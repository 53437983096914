import axios from 'axios';


 export const signInAPI=async(data)=>{
    return new Promise((resolve)=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}/UserLogin`,{data}).then((res,error)=>{
            if(res){
                return resolve(res);
            }
            else{
                return error;
            }
         })
    })
    
}
export const invitationAPI=async(email,token)=>{
    return new Promise((resolve)=>{
        axios.post(`${process.env.REACT_APP_BASE_URL}/userInvitation`,{email,token}).then((res,error)=>{
            if(res){
                return resolve(res);
            }
            else{
                return error;
            }
         })
    })
    
}
