import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import { useTheme } from "@mui/material/styles";
import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Margin,
} from "@mui/icons-material";
import MDTypography from "components/MDTypography";
import Basic from "layouts/authentication/sign-in";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

function dateString(dateString) {
  const date = new Date(dateString);

  // Define month names
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Extract day, month, and year from the date object
  const day = date.getUTCDate();
  const monthIndex = date.getUTCMonth();
  const year = date.getUTCFullYear();

  // Format the date string
  return `${day.toString().padStart(2, "0")}-${monthNames[monthIndex]}-${year}`;
}
function Tables() {
  const navigate = useNavigate();
  const [userData, setUserData] = useState();
  const [loginData, setloginData] = useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [openModal, setOpenModal] = useState(false);

  const formatDuration = (durationString) => {
    try {
      const duration = JSON.parse(durationString);

      const pad = (num) => (num < 10 ? `0${num}` : num);

      const hours = pad(duration.hours || 0);
      const minutes = pad(duration.minutes || 0);
      const seconds = pad(duration.seconds || 0);

      return `${hours}:${minutes}:${seconds}`;
    } catch (error) {
      console.error("Error parsing duration string:", error);
      return "00:00:00"; // Return a default value or handle the error as needed
    }
  };

  const rows =
    userData?.users?.length > 0
      ? userData.users.map((item, index) => {
          return {
            key: index,
            id: index + 1,
            firstName: item?.firstname,
            email: item?.email,
            planType: item?.subscriptionPlan,
            subscriptionID: item?.subscribed_User_Id,
            joinDate: dateString(item?.join_date),
            status: item?.status,
            loginDuration: item?.total_duration_minutes,
            loginDetails: item?.userid,
          };
        })
      : [];

  const fetchData = async () => {
    try {
      const userData1 = window.localStorage.getItem("token");
      const userId = JSON.parse(userData1);

      if (!userId?.authentication) {
        navigate("/");
      } else {
        const config = {
          headers: {
            Authorization: `Bearer ${userId?.authentication}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/Admin/GetSubscribeUserList`,
          config
        );
        setUserData(response?.data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    fetchData(); // Fetch data initially
    const intervalId = setInterval(fetchData, 5000);
    return () => clearInterval(intervalId); // Cleanup function
  }, []);

  const loginDetails = async (id) => {
    const userid = id;
    try {
      const userData1 = window.localStorage.getItem("token");
      const userId = JSON.parse(userData1);

      if (!userId?.authentication) {
        navigate("/");
      } else {
        const config = {
          headers: {
            Authorization: `Bearer ${userId?.authentication}`,
          },
        };
        const data = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/Admin/GetuserLoginDetails/${userid}`,
          config
        );
        setloginData(data);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const openModalHandler = (user) => {
    loginDetails(user);
    setOpenModal(true);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <TableContainer>
        <Table aria-label="customized table">
          <TableHead style={{ display: "contents" }}>
            <TableRow className="user-table-tablerow">
              <TableCell align="center">Sr. No.</TableCell>
              <TableCell align="center">First name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Subscription Plan Type</TableCell>
              <TableCell align="center">Subscription ID</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Join Date</TableCell>
              <TableCell align="center">Login Duration (In min)</TableCell>
              <TableCell align="center">Login Details</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <React.Fragment>
              {(rowsPerPage > 0
                ? rows?.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : rows
              )?.map((row, i) => (
                <TableRow key={row.subscriptionID}>
                  <TableCell
                    size="small"
                    align="center"
                    style={{ fontWeight: "bold" }}
                  >
                    {row.id}
                  </TableCell>
                  <TableCell
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "400",
                    }}
                    align="center"
                  >
                    <div>
                      <h4>{row.firstName}</h4>
                    </div>
                  </TableCell>
                  <TableCell
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontWeight: "400",
                    }}
                    align="center"
                  >
                    <div>
                      <h5>{row?.email}</h5>
                    </div>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "black", fontWeight: "300" }}
                  >
                    <h4
                      className={`${
                        row.planType === "Basic"
                          ? "basic_class"
                          : row.planType === "Pro"
                          ? "prouser"
                          : row.planType === "BBDHU"
                          ? "bbdhuuser"
                          : row.planType === "BPDHU"
                          ? "bpdhuuser"
                          : row.planType === "TS"
                          ? "tsuser"
                          : "stopuser"
                      }`}
                    >
                      {row.planType}
                    </h4>
                  </TableCell>
                  <TableCell
                    align="center"
                    style={{ color: "black", fontWeight: "300" }}
                  >
                    {row.subscriptionID}
                  </TableCell>
                  <TableCell align="center">
                    {row.status === 0 ? (
                      <div className="offline">Offline</div>
                    ) : (
                      <div className="online">Online</div>
                    )}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <div style={{ color: "black", fontWeight: "400" }}>
                      {row.joinDate}
                    </div>{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <div style={{ color: "black", fontWeight: "500" }}>
                      {row.loginDuration} min
                    </div>{" "}
                  </TableCell>
                  <TableCell align="center">
                    {" "}
                    <div style={{ color: "black", fontWeight: "400" }}>
                      <Button
                        style={{
                          backgroundColor: "#1C53A4",
                          color: "white",
                          border: "none",
                          padding: "8px 16px",
                          borderRadius: "8px",
                          cursor: "pointer",
                        }}
                        onClick={() => openModalHandler(row?.loginDetails)}
                      >
                        View More
                      </Button>
                    </div>{" "}
                    <Dialog
                      open={openModal}
                      onClose={() => setOpenModal(false)}
                    >
                      <DialogTitle>User Details</DialogTitle>
                      <DialogContent>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {loginData?.data && loginData.data.length > 0 ? (
                              <Table
                                style={{
                                  width: "100%",
                                  borderCollapse: "collapse",
                                }}
                              >
                                <TableHead sx={{ display: "contents" }}>
                                  <TableRow
                                    style={{ backgroundColor: "#f2f2f2" }}
                                  >
                                    <TableCell style={{ fontWeight: "bold" }}>
                                      Login Time
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>
                                      Logout Time
                                    </TableCell>
                                    <TableCell style={{ fontWeight: "bold" }}>
                                      Duration
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {loginData.data.map((loginDetail, index) => (
                                    <TableRow
                                      key={index}
                                      style={{ borderBottom: "1px solid #ddd" }}
                                    >
                                      <TableCell>
                                        {loginDetail.login_time
                                          ? new Date(
                                              loginDetail.login_time
                                            ).toLocaleString()
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell>
                                        {loginDetail.logout_time
                                          ? new Date(
                                              loginDetail.logout_time
                                            ).toLocaleString()
                                          : "N/A"}
                                      </TableCell>
                                      <TableCell>
                                        {loginDetail.duration
                                          ? formatDuration(loginDetail.duration)
                                          : "N/A"}
                                      </TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            ) : (
                              <MDTypography variant="body1">
                                No data available.
                              </MDTypography>
                            )}
                          </Grid>
                        </Grid>
                      </DialogContent>
                    </Dialog>
                  </TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: "All", value: -1 }]}
                colSpan={3}
                count={rows?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    inputProps: {
                      "aria-label": "rows per page",
                    },
                    native: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>

      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
