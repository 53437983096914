/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { signInAPI } from "../../../services/index";

function Basic() {

  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [data, setData] = useState({ email: "", password: "" });
  const [validation, setValidation] = useState(true);
  const navigate = useNavigate();

  const isValidation=(email,password) =>{
 
    const regexEmail =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      // console.log("regex email",regexEmail.test(email));
    //return (regex.test(email) === false);
    // return (regex.test(email));
    const regexPassword = 
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
    // console.log("regex password",regexPassword.test(password.trim()));
    
    return (regexEmail.test(email)&&regexPassword.test(password.trim()));
  }
useEffect(()=>{
const token = window?.localStorage?.getItem("token");
const userId = JSON.parse(token);
if(userId?.authentication){
  navigate("/dashboard");
}else{
  navigate("/");
}
},[])


  const handleSign = async (e) => {
    setEmail(e.target.value);
    setPassword(e.target.value);
    const isValid= isValidation(email,password);

    if(isValid){
      setData({ email, password });
      const response = await signInAPI({ email, password });
      if (response?.data?.email) {
        localStorage.setItem("token", JSON.stringify(response?.data));
        setMessage(response?.data?.message)
           navigate("/dashboard");
        // setInterval(()=>{
        //   navigate("/dashboard");
        // },2000)
      } else {
        setMessage(response?.data?.message)
        setValidation(true);
        // console.log("message",message);
        // alert(message);
      }
    }
    else{
      setValidation(false);
    }
  
  };


  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Admin Sign in
          </MDTypography>
          <Grid
            container
            spacing={3}
            justifyContent="center"
            sx={{ mt: 1, mb: 2 }}
          ></Grid>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                fullWidth
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type="password"
                label="Password"
                fullWidth
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </MDBox>
            {(message && message != null)&&(validation) ?  (
          <span style={{ color: "red" }}>{message}</span>
        ) : (
          <span style={{ color: "red" }}>{(validation)||email?.length>0?"":"Please Enter Valid Email or Password ."}</span>
        )}
            <MDBox mt={4} mb={1}>
              {email?.length > 0 && password?.length > 0 ? (
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSign}
                >
                  sign in
                </MDButton>
              ) : (
                <span style={{ cursor: "not-allowed" }}>
                  {" "}
                  <MDButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    onClick={handleSign}
                    // disabled="false"
                  >
                    sign in
                  </MDButton>
                </span>
              )}
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Forgot Password?{" "}
                <MDTypography
                  component={Link}
                  to="/sign-up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Forgot
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
