import Footer from "examples/Footer";
import React, { useState, useEffect } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
import { invitationAPI } from "../../services/index.js";
import { bgcolor, fontSize } from "@mui/system";

const SendInvite = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [emailValid, setEmailValid] = useState(true);
  const navigate = useNavigate();

  const emailValidation = (email) => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    //return (regex.test(email) === false);
    return regex.test(email);
  };
  const handleSend = async (e) => {
    setEmail(e.target.value);
    const isEmailValid = emailValidation(email);
    if (isEmailValid) {
      const tokenData = localStorage.getItem("token");
      const userId = JSON.parse(tokenData);
      const token = userId?.authentication;
      if (token) {
        const response = await invitationAPI(email, token);
        if (response?.data?.status === 1) {
          setMessage(response?.data?.message);
          setEmailValid(true);
          // navigate("/dashboard");
        } else {
          setMessage(response?.data?.message);
          setEmailValid(true);
          // alert(response?.data?.message);
        }
      } else {
        setMessage("You are Not Authorized Person");
        //  navigate("/");
      }
    } else {
      setEmailValid(false);
    }
  };
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   console.log("token", token);
  //   if (!token) {
  //     navigate("/");
  //   }
  // }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox
        variant="gradient"
        borderRadius="lg"
        coloredShadow="info"
        mx={2}
        mt={3}
        p={2}
        mb={1}
      >
        <MDTypography variant="h3" fontWeight="medium" color="black" mt={1}>
          Invite user
        </MDTypography>
        <Grid
          container
          flex
          alignItems="center"
          spacing={3}
          sx={{ mt: 3, mb: 2 }}
        >
          <MDBox ml={3}>
            <MDInput
              type="email"
              label="Enter Email"
              value={email}
              fullWidth
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </MDBox>
          <MDBox ml={3}>
            {email?.length > 0 ? (
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSend}
              >
                Send Invitaiton
              </MDButton>
            ) : (
              <span style={{ cursor: "not-allowed" }}>
                <MDButton
                  variant="gradient"
                  color="info"
                  fullWidth
                  onClick={handleSend}
                  // disabled="false"
                >
                  Send Invitaiton
                </MDButton>
              </span>
            )}
          </MDBox>
        </Grid>
        {message === "User Already exists" ? (
          <span style={{ color: "red" }}>{message}</span>
        ) : message ===
          "Please check your email and use the code to register." ? (
          <span style={{ color: "green" }}>Code sent</span>
        ) : (
          <span style={{ color: "red" }}>
            {emailValid || email?.length > 0
              ? ""
              : "Please Enter Valid Email Address."}
          </span>
        )}
      </MDBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
};

export default SendInvite;
