import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

// Data
import data from "layouts/dashboard/components/Projects/data";
import axios from "axios";
import Swal from "sweetalert2";


function Projects() {
  // const { columns, rows } = data();
  const [menu, setMenu] = useState(null);

  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [companyPosition, setPosition] = React.useState("");
  const [profileInfo, setProfileInfo] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [mobile, setPhoneNumber] = React.useState("");
  // const [gender, setGender] = React.useState("")


  const formUserData = {
    firstname: firstName,
    lastname: lastName,
    position: companyPosition,
    info: profileInfo,
    location: country,
    // gender: gender,
    mobilenumber: mobile
  }
  
  const userData = window.localStorage.getItem("token");
  const userId = JSON.parse(userData);

  const handleSend = async (e) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${userId?.authentication}`,
        },
      };
      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/Admin/AdminProfile/${userId?.email}`,
        formUserData,
        config
      );
      if(data === "Profile Update Successfully" ){
        Swal.fire({
          icon: "success",
          title: "Profile Updated successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      }
      else{
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      }
    } catch (error) {
      console.log(error, "errori");
    }
  };

  // const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  // const closeMenu = () => setMenu(null);

  // const renderMenu = (
  //   <Menu
  //     id="simple-menu"
  //     anchorEl={menu}
  //     anchorOrigin={{
  //       vertical: "top",
  //       horizontal: "left",
  //     }}
  //     transformOrigin={{
  //       vertical: "top",
  //       horizontal: "right",
  //     }}
  //     open={Boolean(menu)}
  //     onClose={closeMenu}
  //   >
  //     <MenuItem onClick={closeMenu}>Action</MenuItem>
  //     <MenuItem onClick={closeMenu}>Another action</MenuItem>
  //     <MenuItem onClick={closeMenu}>Something else</MenuItem>
  //   </Menu>
  // );

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Profile
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox
        variant="gradient"
        borderRadius="lg"
        mx={2}
        p={2}
        mb={1}
        pt={4}
        pb={3}
        px={3}
        display="flex"
      >
        <MDBox component="form" role="form">
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="First Name"
              value={firstName}
              onChange={(e) => {
                setFirstName(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Last Name"
              mx={2}
              mt={3}
              p={2}
              mb={1}
              value={lastName}
              onChange={(e) => {
                setLastName(e.target.value);
              }}
            />
          </MDBox>
          {/* <MDBox
            mb={2}
          >
            <FormControl fullWidth fullHeight>
              <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={gender}
                label="Gender"
                mx={2}
                mt={3}
                p={2}
                mb={1}
                sx={{
                  minHeight: 40,
                }}
                onChange={(e)=>{
                  setGender(e.target.value)
                }}
              >
                <MenuItem value={'male'}>Male</MenuItem>
                <MenuItem value={'female'}>Female</MenuItem>
                <MenuItem value={'other'}>Other</MenuItem>
              </Select>
            </FormControl>
            </MDBox> */}
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="position in company"
              mx={2}
              mt={3}
              p={2}
              mb={1}
              value={companyPosition}
              onChange={(e) => {
                setPosition(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="profile information"
              mx={2}
              mt={3}
              p={2}
              mb={1}
              value={profileInfo}
              onChange={(e) => {
                setProfileInfo(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="text"
              label="Country"
              mx={2}
              mt={3}
              p={2}
              mb={1}
              value={country}
              onChange={(e) => {
                setCountry(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={2}>
            <MDInput
              type="number"
              label="Mobile"
              mx={2}
              mt={3}
              p={2}
              mb={1}
              value={mobile}
              onChange={(e) => {
                setPhoneNumber(e.target.value);
              }}
            />
          </MDBox>
          <MDBox mb={3}>
            <span style={{ cursor: "not-allowed" }}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSend}
              >
                Update
              </MDButton>
            </span>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default Projects;
