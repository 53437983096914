// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import axios from "axios";

function Dashboard() {
  const { sales, tasks } = reportsLineChartData;
  const navigate = useNavigate();
  const [userCount, setUserCount] = useState();
  const [BasicuserCount, setBasicUserCount] = useState();
  const [PrimeuserCount, setPrimeUserCount] = useState();
  const [TSuserCount, setTSUserCount] = useState();
  const [BBDHUuserCount, setBBDHUUserCount] = useState();
  const [BPDHUuserCount, setBPDHUUserCount] = useState();

 useEffect(() => {
   const fetchData = async () => {
     try {
       const userData = window.localStorage.getItem("token");
       const userId = JSON.parse(userData);

       if (!userId?.authentication) {
         navigate("/");
       } else {
         const config = {
           headers: {
             Authorization: `Bearer ${userId?.authentication}`,
           },
         };
         const data = await axios.get(
           `${process.env.REACT_APP_BASE_URL}/Admin/UserCount`,
           config
         );
         const primedata = await axios.get(
           `${process.env.REACT_APP_BASE_URL}/Admin/Subscriber_User_Count`,
           config
         );
         setUserCount(data?.data?.User_Count);
         setBasicUserCount(primedata?.data?.Basic_User_Count);
         setPrimeUserCount(primedata?.data?.Prime_User_Count);
         setTSUserCount(primedata?.data?.TS_User_Count);
         setBBDHUUserCount(primedata?.data?.BBDHU_User_Count);
         setBPDHUUserCount(primedata?.data?.BPDHU_User_Count);
       }
     } catch (error) {
       console.log(error, "error");
     }
   };

   fetchData();
 }, []);


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Users"
                count={Number(userCount)}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Basic Users"
                count={Number(BasicuserCount)}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Pro Users"
                count={Number(PrimeuserCount)}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="TS Users"
                count={Number(TSuserCount)}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="BBDHU Users"
                count={Number(BBDHUuserCount)}
              />
            </MDBox>
          </Grid>

          <Grid item xs={12} md={4} lg={2}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="BPDHU Users"
                count={Number(BPDHUuserCount)}
              />
            </MDBox>
          </Grid>
        </Grid>

        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={12}>
              <Projects />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
